var arslunaStickySidebar = function($) {
    "use strict";
    var sidebar, created = false;

    function init(){
        var screen = getNumSkelSize();
        if (screen <= 3) {           
            if (created) {
                arslunaStickySidebar.sidebar.destroy();
            }
            return false;
        }
        if (created) {
            update();
        } else {
            if ($('#sidebar').length > 0) {
                arslunaStickySidebar.sidebar = new StickySidebar('#sidebar', {
                    topSpacing: topSpacingGet(0),
                    bottomSpacing: 20,
                    containerSelector: '.page-left-column-content',
                    innerWrapperSelector: '.sidebar__inner'
                });
            }             
            created = true;
        }
    } // END init

    function update(){
        var screen = getNumSkelSize();
        if ($('#sidebar').length == 0) {
            return false;
        }
        if (screen <= 3) {            
            destroy();
        } else {
            if (created) {
                arslunaStickySidebar.sidebar.updateSticky();
            } else {
                init();
            }
        }
    } // END update;

    function updateTimeouted(){
        window.setTimeout(arslunaStickySidebar.update, 30);
    } // END updateDelay

    function destroy(){
        try {
            if (created) {
                arslunaStickySidebar.sidebar.destroy();
            }
            $("#sidebar").removeClass("is-affixed").removeAttr("style");
            $("#sidebar .sidebar__inner").removeAttr("style");
        } catch(e){
        }
    } // END destroy;

    function topSpacingGet(){
        var spacing = $("#sidebar").offset().top;
        return spacing;
    } // END stickySidebarTopSpacingGet

    function bottomSpacingGet() {
        var spacing = $("#footer-wrapper").outerHeight();
        return spacing;
    } // END stickySidebarBottomSpacingGet

    return {
        sidebar         : sidebar,
        init            : init,
        update          : update,
        updateTimeouted : updateTimeouted,
        destroy         : destroy
    }

}(jQuery); // END arslunaStickySidebar

$(window).on("load",function(){
    arslunaStickySidebar.init();
});

$(window).on("load", arslunaStickySidebar.updateTimeouted);
$(window).on("resize", arslunaStickySidebar.updateTimeouted);
$(window).on("orientationchange",arslunaStickySidebar.updateTimeouted);